<template>
  <div>
    <validation-observer
      ref="refFormObserver"
      #default="{ invalid, pristine }"
    >
      <b-modal
        id="modal-modify-change-passengers-info-VN1A"
        :title="$t('reservation.editInfoPaxList')"
        title-class="text-airline font-medium-4 fw-600"
        centered
        size="lg"
        body-class="pt-0"
        no-close-on-backdrop
        no-enforce-focus
        @show="showModalPassengersInfo"
      >
        <template #modal-footer="{close}">
          <b-button
            variant="outline-secondary"
            class="rounded-pill"
            @click="close()"
          >
            {{ $t('reservation.back') }}
          </b-button>

          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            class="btn-gradient"
            pill
            :disabled="invalid||pristine"
            @click="submitChangeInfoHandle"
          >
            <!-- {{ $t('reservation.historyActions.CALCULATE_PRICE') }} -->
            {{ $t('reservation.confirm') }}
          </b-button>
        </template>

        <div v-if="dataToEdit">
          <b-alert
            variant="warning"
            show
            class="p-75"
          >
            <div class="text-danger fw-700">
              - Bạn chỉ được phép sửa tối đa 1 ký tự ở họ và tối đa 1 ký tự ở tên + tên đệm và chỉ được sửa duy nhất 1 lần ! <br>
              - Áp dụng sửa trên chuyến bay số hiệu VN. Với hành trình quốc tế có liên danh hãng khác vui lòng liên hệ booker xử lý ! <br>
              - Mỗi lần thao tác chỉ sửa thông tin của 1 hành khách.
            </div>
          </b-alert>

          <b-row
            v-for="(pax) of dataToEdit"
            :key="pax.paxId"
            class="mx-0 mb-75 py-50 border-secondary rounded-lg passenger_row"
            no-gutters
          >
            <b-col
              cols="12"
              :class="`d-flex-between ${pax.isEdit ? 'mb-75' : ''}`"
            >
              <span class="fw-700">
                {{ pax.dataShow }}
              </span>
              <b-button
                :variant="pax.isEdit ? 'outline-danger' : 'primary'"
                size="sm"
                class="rounded-lg mx-25"
                @click="handleEditPassenger(pax.paxId)"
              >
                {{ pax.isEdit ? 'Huỷ' : 'Sửa' }}
              </b-button>
            </b-col>

            <template v-if="pax.isEdit">
              <!-- ANCHOR - paxType -->
              <b-col
                cols="12"
                md="4"
              >
                <validation-provider
                  #default="validationContext"
                  name="Loại hành khách"
                  rules=""
                >
                  <b-form-group class="form_label">
                    <template #label>
                      <span>
                        {{ $t('reservation.paxs') }}
                        <span class="text-danger ml-25">(*)</span>
                      </span>
                    </template>
                    <v-select
                      id="pax-type-select"
                      v-model="pax.paxType"
                      :options="typeOfPassengerOptions.filter(it => it.value !== 'INFANT')"
                      :reduce="val => val.value"
                      :state="getValidationState(validationContext) === false ? false : null"
                      label="text"
                      :clearable="false"
                      :disabled="dataToEdit.paxType === 'INFANT'"
                      :placeholder="`${$t('reservation.Title')} (*)`"
                      :selectable="(option) => option.value !== 'INFANT'"
                    >
                      <template #option="data">
                        <span class="font-medium-1 fw-600">
                          <!-- {{ $t(`reservation.titleOptions.${dataToEdit.paxType}.${data.text}`) }} -->
                          {{ $t(data.text) }}
                        </span>
                      </template>

                      <template #selected-option="data">
                        <span class="font-medium-1 fw-600">
                          {{ $t(data.text) }}
                          <!-- {{ $t(`reservation.titleOptions.${dataToEdit.paxType}.${data.text}`) }} -->
                        </span>
                      </template>
                    </v-select>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- ANCHOR - lastName -->
              <b-col
                cols="12"
                md="4"
              >
                <validation-provider
                  #default="validationContext"
                  :name="$t('reservation.lastName')"
                  :rules="`required|compareStringChange:${pax.defaultLastName}`"
                >
                  <b-form-group class="form_label">
                    <template #label>
                      <span>
                        {{ $t('reservation.lastName') }}
                        <span class="text-danger ml-25">(*)</span>
                      </span>
                    </template>
                    <b-form-input
                      :id="`lastName${pax.paxId}`"
                      v-model="pax.lastName"
                      lazy-formatter
                      :formatter="formatterNameOnly"
                      class="font-medium-1 fw-600 text-uppercase"
                      :state="getValidationState(validationContext) === false ? false : null"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- ANCHOR - firstName -->
              <b-col
                cols="12"
                md="4"
              >
                <validation-provider
                  #default="validationContext"
                  :name="$t('reservation.firstName')"
                  :rules="`required|compareStringChange:${pax.defaultFirstName}`"
                >
                  <b-form-group class="form_label">
                    <template #label>
                      <span>
                        {{ $t('reservation.firstName') }}
                        <span class="text-danger ml-25">(*)</span>
                      </span>
                    </template>

                    <!-- v-input-formatter.upperCase.allowOnlyLetters.removeAccents -->
                    <b-form-input
                      :id="`firstName${pax.paxId}`"
                      v-model="pax.firstName"
                      lazy-formatter
                      :formatter="formatterNameOnly"
                      class="font-medium-1 fw-600 text-uppercase"
                      :state="getValidationState(validationContext) === false ? false : null"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- ANCHOR - Birthday -->
              <b-col
                v-if="['CHILD'].includes(pax.paxType)"
                cols="12"
                md="4"
              >
                <validation-provider
                  #default="validationContext"
                  :name="$t('reservation[\'DoB\']')"
                  :rules="`${['CHILD'].includes(pax.paxType) ? 'required|' : ''}ageGroup:OW,${getBookingData.itineraries[0][0].departure.at},${pax.paxType}`"
                  vid="passenger-birthday"
                >
                  <b-form-group class="form_label">
                    <template #label>
                      <span>
                        {{ $t('reservation[\'DoB\']') }}
                        <span class="text-danger ml-25">(*)</span>
                        <!-- <small class="text-italic">(định dạng <span class="text-danger">DD/MM/YYYY</span>)</small> -->
                      </span>
                    </template>
                    <flat-pickr
                      :id="`passenger-birthday${pax.paxId}`"
                      v-model="pax.birthday"
                      class="d-none"
                    />
                    <div
                      class="form-control p-0"
                      :class="validationContext.errors[0] ? 'is-invalid' : ''"
                    >
                      <flat-pickr
                        :id="`passenger-birthday${pax.paxId}`"
                        v-model="pax.birthday"
                        :name="$t('reservation[\'DoB\']')"
                        class="form-control px-50 py-0"
                        :config="{
                            ...configFlatPickr,
                            altInputClass: 'form-control input px-50 py-0 border-transparent',
                        }"
                        :placeholder="$t('reservation[\'formatDoB\']')"
                      />
                    </div>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </template>
          </b-row>
        </div>

        <div
          v-else
          class="d-flex-center"
        >
          <b-spinner variant="primary" />
        </div>

        <div class="mt-1">
          <b-form-checkbox
            v-model="isRecalculate"
            name="retain-checkbox"
          >
            <div class="text-body fw-600 font-medium-1">
              Tính lại giá
            </div>
          </b-form-checkbox>
          <small
            v-if="isRecalculate"
            class="text-warning"
          > (*) {{ $t('reservation.recalculatePrice[\'The system will automatically recalculate the price for all passengers on the booking\']') }} ! </small>
        </div>
        <!-- ANCHOR - Retain and BargainFinder -->
        <b-card
          v-if="canPriceBargainFinder && isRecalculate"
          body-class="py-1"
        >
          <div class="d-flex flex-wrap gap-12px justify-content-around">
            <b-form-checkbox
              v-model="isBargainFinder"
              name="retain-checkbox"
              class="mx-1"
            >
              <div class="text-body fw-600 font-medium-1">
                {{ $t('reservation.bargainFinder') }}
              </div>
            </b-form-checkbox>
            <b-form-checkbox
              v-model="keepGroup"
              class="mx-1"
              name="keepGroup-checkbox"
            >
              <div class="text-body fw-600 font-medium-1">
                {{ $t('reservation.keepGroup') }}
              </div>
            </b-form-checkbox>
          </div>
        </b-card>
      </b-modal>
    </validation-observer>

    <!-- ======================== Confirm new price: MODAL SHOW PRICE =================================== -->
    <BModal
      id="modal-modify-pax-info-recalculate-show-price-VN1A"
      :title="$t('reservation.showCalculatePrice.title')"
      title-class="text-airline font-medium-4 font-weight-bolder"
      body-class="px-75"
      centered
      no-close-on-backdrop
      no-close-on-esc
      size="lg"
      hide-header-close
    >
      <template #modal-footer="{close}">
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          class="rounded-pill px-2"
          @click="handleCloseModalShowPriceNew(close)"
        >
          {{ $t('reservation.close') }}
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="btn-gradient"
          pill
          @click="handleSavePrice"
        >
          <span class="">LƯU GIÁ MỚI</span>
        </b-button>
      </template>

      <div
        v-if="!isEmpty(newPriceItinerary)"
        class="position-relative"
      >
        <div
          v-if="tripDetailsItinerary"
          class="mb-1"
        >
          <div class="fw-700 font-medium-2">
            {{ $t('flight.flightInfo') }}
          </div>
          <b-form-textarea
            :value="tripDetailsItinerary"
            class="font-weight-bolder text-uppercase"
            rows="2"
            style="font-size: 16px; line-height: 24px; background-color:#ffffc0; color:#000; font-family:'Calibri', sans-serif;  overflow: auto;"
          />
        </div>

        <BTableLite
          v-if="!isEmpty(newPriceItinerary)"
          bordered
          responsive
          small
          hover
          striped
          body-class="p-0"
          class="mb-1 rounded"
          thead-class="text-center font-weight-bolder text-nowrap"
          tbody-class="text-center text-nowrap"
          :fields="['passengerType', 'net', 'tax', 'total', 'quantity', 'totalAmount']"
          :items="newPriceItinerary"
        >
          <template
            v-for="(column, indexColumn) in ['passengerType', 'net', 'tax', 'total', 'quantity', 'totalAmount']"
            #[`head(${column})`]="data"
          >
            <div :key="indexColumn">
              <span>{{ $t(`reservation.${data.column}`) }}</span>
            </div>
          </template>

          <template #cell(passengerType)="{ item }">
            {{ item.paxType }}
          </template>
          <template #cell(net)="{ item }">
            {{ formatCurrency(item.netFare) }}
          </template>
          <template #cell(tax)="{ item }">
            {{ formatCurrency(item.tax) }}
          </template>
          <template #cell(total)="{ item }">
            <span class="fw-600">{{ formatCurrency(item.totalPrice) }}</span>
          </template>
          <template #cell(quantity)="{ item }">
            {{ item.paxNum ? formatCurrency(item.paxNum) : 1 }}
          </template>
          <template #cell(totalAmount)="{ item }">
            <span class="fw-800">{{ item.paxNum ? formatCurrency(item.totalPrice * item.paxNum) : formatCurrency(item.totalPrice) }}</span>
          </template>
        </BTableLite>

        <p :class="`text-right text-body text-nowrap mr-md-2 ${isMobileView ? 'font-medium-1' : 'font-medium-2'}`">
          Tổng tiền: <span :class="`text-airline fw-800 ${isMobileView ? 'font-medium-1' : 'font-medium-3'}`">{{ formatCurrency(newPriceItinerary.reduce((total, item) => total + (item.paxNum ? (item.totalPrice * item.paxNum) : item.totalPrice), 0)) }} VND</span>
        </p>

        <BAlert
          :show="isWarningItinerary"
          variant="warning"
          class="py-75 mt-1 text-center px-75"
        >
          <span :class="`fw-700 ${isMobileView ? 'font-medium-1' : 'font-medium-2'}`">
            GIÁ VÉ CÓ SỰ THAY ĐỔI, VUI LÒNG KIỂM TRA !!!
          </span>
        </BAlert>
      </div>
    </BModal>
  </div>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BCard,
  BButton,
  BSpinner,
  BFormGroup,
  BFormInput,
  BAlert,
  BFormInvalidFeedback,
  BFormTextarea,
  BTableLite,
  VBTooltip,
  BFormCheckbox,
} from 'bootstrap-vue'
import {
  ref, computed, watch,
} from '@vue/composition-api'
import isEmpty from 'lodash/isEmpty'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { Vietnamese } from 'flatpickr/dist/l10n/vn'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'

import { typeOfPassengerOptions } from '@/constants/selectOptions'
import { formatCurrency, formatDateMonth } from '@/@core/utils/filter'
import i18n from '@/libs/i18n'
import store from '@/store'

import formValidation from '@core/comp-functions/forms/form-validation'
import { formatterNameOnly } from '@core/comp-functions/forms/formatter-input'

import useReservationHandle from '@reservation/useReservationHandle'

import useToast from '@useToast'

export default {
  components: {
    BModal,
    BSpinner,
    BFormGroup,
    BFormInput,
    BRow,
    BCard,
    BCol,
    BButton,
    BFormTextarea,
    BFormInvalidFeedback,
    BTableLite,
    BAlert,
    BFormCheckbox,

    ValidationProvider,
    ValidationObserver,

    flatPickr,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  setup() {
    const {
      getBookingData,
      modifyPaxInfo,
      delay,
      recalculatePriceItinerary,
      refetchBookingData,
    } = useReservationHandle()
    const { toastSuccess, toastError } = useToast()

    const isConfirm = ref(false)
    const dataToEdit = ref(null)
    const payloadData = ref(null)

    const isRecalculate = ref(true)
    const isBargainFinder = ref(false)
    const keepGroup = ref(false)
    const isRetain = ref(false)

    const locale = computed(() => i18n.locale).value
    const source = computed(() => getBookingData.value?.source)
    const dataPax = computed(() => getBookingData.value?.paxLists?.filter(p => !['INFANT'].includes(p.paxType)) || [])

    const tripDetailsItinerary = ref(null)
    const newPriceItinerary = ref(null)
    const isWarningItinerary = ref(false)

    const prePayload = ref(null)

    // F2, F3 tự bật isPriceBargainFinder trong employeeConfig mới được chọn tính giá rẻ nhất
    const canPriceBargainFinder = computed(() => {
      const employeeConfig = store.getters['userStore/getEmployeeConfig']
      const isRoleF1 = store.getters['userStore/getRoleMama']
      const { isPriceBargainFinder } = employeeConfig
      return !!(!isRoleF1 && isPriceBargainFinder) || isRoleF1
    })

    watch(() => isRecalculate.value, val => {
      if (!val) {
        isRetain.value = false
        keepGroup.value = false
        isBargainFinder.value = false
      }
    })

    const resetContactDataToEdit = () => {
      dataToEdit.value = dataPax.value.map((pax, index) => ({
        ...pax,
        isEdit: false,
        paxId: pax.paxId || `${index + 1}`,
        dataShow: `${pax.paxId}. ${pax.lastName} / ${pax.firstName} ${pax.birthday ? `(${formatDateMonth(pax.birthday)})` : ''}`,
        firstName: pax.firstName || '',
        lastName: pax.lastName || '',
        birthday: pax.birthday || '',
        defaultFirstName: pax.firstName || '',
        defaultLastName: pax.lastName || '',
        defaultBirthday: pax.birthday || '',
        defaultPaxType: pax.paxType || 'ADULT',
        title: pax.title || '',
        paxType: pax.paxType || 'ADULT',
        parentPaxId: pax.parentPaxId || '',
        familyId: pax.familyId || '',
        pricingInfo: {
          currency: 'string',
          netFare: 0,
          tax: 0,
          total: 0,
        },
        gender: pax.gender || '',
      }))
    }

    const { refFormObserver, getValidationState } = formValidation(resetContactDataToEdit)

    // Modal 1
    function showModalPassengersInfo() {
      resetContactDataToEdit()
      isRecalculate.value = true
      isBargainFinder.value = false
      keepGroup.value = false
      isRetain.value = false

      tripDetailsItinerary.value = null
      newPriceItinerary.value = []
      isWarningItinerary.value = false

      prePayload.value = null

      payloadData.value = null
    }

    // Modal 1: submit
    async function submitChangeInfoHandle() {
      const titleMsg = () => this.$createElement('div', {
        domProps: { innerHTML: 'Xác nhận thay đổi thông tin hành khách?' },
      })

      this.$bvModal
        .msgBoxConfirm(titleMsg(), {
          title: 'Thay đổi thông tin',
          size: 'sm',
          okVariant: 'info',
          okTitle: this.$t('modal.confirm'),
          cancelTitle: this.$t('reservation.back'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: true,
          centered: true,
        })
        .then(async value => {
          if (value) {
            try {
              const passengers = dataToEdit.value.filter(pax => pax.isEdit)
              const payload = {
                paxUpdates: passengers.map(pax => ({
                  paxInfo: {
                    firstName: pax.firstName,
                    lastName: pax.lastName,
                    title: pax.title,
                    paxType: pax.paxType,
                    birthday: pax?.birthday || null,
                    paxId: pax.paxId,
                    parentPaxId: pax?.parentPaxId || null,
                    familyId: pax?.familyId || pax.parentPaxId,
                  },
                  operation: 'UPDATE',
                  id: '1',
                })),
                rePrice: isRecalculate.value,
                bargainFinder: isBargainFinder.value,
                retain: isRetain.value,
                keepGroup: keepGroup.value,
              }

              prePayload.value = payload
              const isUsingCommand = true
              const isShowToast = false
              const isReload = !isRecalculate.value // nếu ko tính giá thì reload lại và đóng modal

              this.$bvModal.show('modal-api-loading')

              const res = await modifyPaxInfo(getBookingData.value, payload, isShowToast, isUsingCommand, isReload)

              if (isRecalculate.value) {
                tripDetailsItinerary.value = res?.trips
                newPriceItinerary.value = res?.paxPrice
                isWarningItinerary.value = res?.isWarning
                await delay(200)

                this.$bvModal.show('modal-modify-pax-info-recalculate-show-price-VN1A')
              } else {
                toastSuccess({
                  title: 'messagesList.success',
                  content: 'messagesList.agency.edited',
                })
                this.$bvModal.hide('modal-modify-change-passengers-info-VN1A')
              }
            } catch (error) {
              console.error('error in submitChangeInfoHandle: ', error)
            } finally {
              this.$bvModal.hide('modal-api-loading')
            }
          }
        })
    }

    const configFlatPickr = {
      dateFormat: 'Y-m-d',
      minDate: '1.1.1920',
      maxDate: 'today',
      locale: locale === 'vi' ? Vietnamese : null,
      allowInput: true,
      altInput: true,
      altFormat: 'd-m-Y',
      shorthandCurrentMonth: true,
      disableMobile: true,
    }

    function handleEditPassenger(paxId) {
      dataToEdit.value = dataToEdit.value.map(p => ({
        ...p,
        lastName: p.defaultLastName,
        firstName: p.defaultFirstName,
        birthday: p.defaultBirthday,
        paxType: p.defaultPaxType,
        isEdit: p.paxId === paxId && !p.isEdit,
      }))
    }

    function handleSavePrice() {
      if (prePayload.value) {
        this.$bvModal.show('modal-api-loading')

        recalculatePriceItinerary({
          rePrice: prePayload.value.rePrice,
          bargainFinder: prePayload.value.bargainFinder,
          keepGroup: prePayload.value.keepGroup,
          retain: true,
          source: getBookingData.value.source,
          pnrNumber: getBookingData.value.bookingCode,
        })
          .then(() => {
            toastSuccess({
              title: 'messagesList.success',
              content: 'messagesList.reservation.saveRecalculatePriceSuccess',
            })
          })
          .catch(() => {
            toastError({
              title: 'messagesList.error',
              content: 'messagesList.reservation.saveRecalculatePriceError',
            })
          })
          .finally(() => {
            this.$bvModal.hide('modal-api-loading')

            this.$bvModal.hide('modal-modify-change-passengers-info-VN1A')
            this.$bvModal.hide('modal-modify-pax-info-recalculate-show-price-VN1A')
          })
      }
    }

    function handleCloseModalShowPriceNew(close) {
      this.$bvModal.hide('modal-modify-change-passengers-info-VN1A')
      this.$bvModal.hide('modal-modify-pax-info-recalculate-show-price-VN1A')
      close()
      refetchBookingData()
    }

    function validateCompareStrings(defaultString, newValue) {
      const len1 = defaultString.length
      const len2 = newValue.length

      if (Math.abs(len1 - len2) > 1) {
        return false
      }

      let differences = 0 // Đếm số lượng khác biệt
      let i = 0
      let j = 0 // Chỉ số duyệt từng chuỗi

      while (i < len1 && j < len2) {
        if (defaultString[i] !== newValue[j]) {
          differences += 1
          if (differences > 1) {
            return false // Kết thúc sớm nếu có hơn 1 khác biệt
          }

          // Xử lý trường hợp thêm, bớt hoặc thay thế ký tự
          if (len1 > len2) {
            i += 1 // Bỏ qua ký tự trong chuỗi dài hơn
          } else if (len1 < len2) {
            j += 1 // Bỏ qua ký tự trong chuỗi ngắn hơn
          } else {
            i += 1
            j += 1 // Tiếp tục cả hai nếu độ dài bằng nhau
          }
        } else {
          i += 1
          j += 1
        }
      }

      if (i < len1 || j < len2) {
        differences += 1
      }

      return differences <= 1
    }

    extend('compareStringChange', {
      validate(value, { defaultValue }) {
        const validate = validateCompareStrings(defaultValue, value.toUpperCase())
        return validate || 'Chỉ được sửa tối đa 1 ký tự cho {_field_}'
      },
      params: ['defaultValue'],
    })

    return {
      getBookingData,
      dataToEdit,
      formatterNameOnly,
      showModalPassengersInfo,
      formatCurrency,
      submitChangeInfoHandle,
      formatDateMonth,
      source,
      isEmpty,
      refFormObserver,
      getValidationState,
      isConfirm,
      configFlatPickr,
      handleEditPassenger,
      typeOfPassengerOptions,

      handleSavePrice,
      isRetain,
      keepGroup,
      isRecalculate,
      isBargainFinder,
      canPriceBargainFinder,

      tripDetailsItinerary,
      newPriceItinerary,
      isWarningItinerary,

      handleCloseModalShowPriceNew,
      validateCompareStrings,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';

.form_label {
  legend {
    padding-bottom: 4px !important;
  }
}

.passenger_row {
  > div {
    padding: 0 7px !important;
  }
}
</style>
