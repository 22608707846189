var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('validation-observer', {
    ref: "refFormObserver",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var invalid = _ref.invalid,
          pristine = _ref.pristine;
        return [_c('b-modal', {
          attrs: {
            "id": "modal-modify-change-passengers-info-VN1A",
            "title": _vm.$t('reservation.editInfoPaxList'),
            "title-class": "text-airline font-medium-4 fw-600",
            "centered": "",
            "size": "lg",
            "body-class": "pt-0",
            "no-close-on-backdrop": "",
            "no-enforce-focus": ""
          },
          on: {
            "show": _vm.showModalPassengersInfo
          },
          scopedSlots: _vm._u([{
            key: "modal-footer",
            fn: function fn(_ref2) {
              var close = _ref2.close;
              return [_c('b-button', {
                staticClass: "rounded-pill",
                attrs: {
                  "variant": "outline-secondary"
                },
                on: {
                  "click": function click($event) {
                    return close();
                  }
                }
              }, [_vm._v(" " + _vm._s(_vm.$t('reservation.back')) + " ")]), _c('b-button', {
                directives: [{
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: 'rgba(113, 102, 240, 0.15)',
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: {
                    "400": true
                  }
                }],
                staticClass: "btn-gradient",
                attrs: {
                  "pill": "",
                  "disabled": invalid || pristine
                },
                on: {
                  "click": _vm.submitChangeInfoHandle
                }
              }, [_vm._v(" " + _vm._s(_vm.$t('reservation.confirm')) + " ")])];
            }
          }], null, true)
        }, [_vm.dataToEdit ? _c('div', [_c('b-alert', {
          staticClass: "p-75",
          attrs: {
            "variant": "warning",
            "show": ""
          }
        }, [_c('div', {
          staticClass: "text-danger fw-700"
        }, [_vm._v(" - Bạn chỉ được phép sửa tối đa 1 ký tự ở họ và tối đa 1 ký tự ở tên + tên đệm và chỉ được sửa duy nhất 1 lần ! "), _c('br'), _vm._v(" - Áp dụng sửa trên chuyến bay số hiệu VN. Với hành trình quốc tế có liên danh hãng khác vui lòng liên hệ booker xử lý ! "), _c('br'), _vm._v(" - Mỗi lần thao tác chỉ sửa thông tin của 1 hành khách. ")])]), _vm._l(_vm.dataToEdit, function (pax) {
          return _c('b-row', {
            key: pax.paxId,
            staticClass: "mx-0 mb-75 py-50 border-secondary rounded-lg passenger_row",
            attrs: {
              "no-gutters": ""
            }
          }, [_c('b-col', {
            class: "d-flex-between ".concat(pax.isEdit ? 'mb-75' : ''),
            attrs: {
              "cols": "12"
            }
          }, [_c('span', {
            staticClass: "fw-700"
          }, [_vm._v(" " + _vm._s(pax.dataShow) + " ")]), _c('b-button', {
            staticClass: "rounded-lg mx-25",
            attrs: {
              "variant": pax.isEdit ? 'outline-danger' : 'primary',
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                return _vm.handleEditPassenger(pax.paxId);
              }
            }
          }, [_vm._v(" " + _vm._s(pax.isEdit ? 'Huỷ' : 'Sửa') + " ")])], 1), pax.isEdit ? [_c('b-col', {
            attrs: {
              "cols": "12",
              "md": "4"
            }
          }, [_c('validation-provider', {
            attrs: {
              "name": "Loại hành khách",
              "rules": ""
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(validationContext) {
                return [_c('b-form-group', {
                  staticClass: "form_label",
                  scopedSlots: _vm._u([{
                    key: "label",
                    fn: function fn() {
                      return [_c('span', [_vm._v(" " + _vm._s(_vm.$t('reservation.paxs')) + " "), _c('span', {
                        staticClass: "text-danger ml-25"
                      }, [_vm._v("(*)")])])];
                    },
                    proxy: true
                  }], null, true)
                }, [_c('v-select', {
                  attrs: {
                    "id": "pax-type-select",
                    "options": _vm.typeOfPassengerOptions.filter(function (it) {
                      return it.value !== 'INFANT';
                    }),
                    "reduce": function reduce(val) {
                      return val.value;
                    },
                    "state": _vm.getValidationState(validationContext) === false ? false : null,
                    "label": "text",
                    "clearable": false,
                    "disabled": _vm.dataToEdit.paxType === 'INFANT',
                    "placeholder": "".concat(_vm.$t('reservation.Title'), " (*)"),
                    "selectable": function selectable(option) {
                      return option.value !== 'INFANT';
                    }
                  },
                  scopedSlots: _vm._u([{
                    key: "option",
                    fn: function fn(data) {
                      return [_c('span', {
                        staticClass: "font-medium-1 fw-600"
                      }, [_vm._v(" " + _vm._s(_vm.$t(data.text)) + " ")])];
                    }
                  }, {
                    key: "selected-option",
                    fn: function fn(data) {
                      return [_c('span', {
                        staticClass: "font-medium-1 fw-600"
                      }, [_vm._v(" " + _vm._s(_vm.$t(data.text)) + " ")])];
                    }
                  }], null, true),
                  model: {
                    value: pax.paxType,
                    callback: function callback($$v) {
                      _vm.$set(pax, "paxType", $$v);
                    },
                    expression: "pax.paxType"
                  }
                }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
              }
            }], null, true)
          })], 1), _c('b-col', {
            attrs: {
              "cols": "12",
              "md": "4"
            }
          }, [_c('validation-provider', {
            attrs: {
              "name": _vm.$t('reservation.lastName'),
              "rules": "required|compareStringChange:".concat(pax.defaultLastName)
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(validationContext) {
                return [_c('b-form-group', {
                  staticClass: "form_label",
                  scopedSlots: _vm._u([{
                    key: "label",
                    fn: function fn() {
                      return [_c('span', [_vm._v(" " + _vm._s(_vm.$t('reservation.lastName')) + " "), _c('span', {
                        staticClass: "text-danger ml-25"
                      }, [_vm._v("(*)")])])];
                    },
                    proxy: true
                  }], null, true)
                }, [_c('b-form-input', {
                  staticClass: "font-medium-1 fw-600 text-uppercase",
                  attrs: {
                    "id": "lastName".concat(pax.paxId),
                    "lazy-formatter": "",
                    "formatter": _vm.formatterNameOnly,
                    "state": _vm.getValidationState(validationContext) === false ? false : null
                  },
                  model: {
                    value: pax.lastName,
                    callback: function callback($$v) {
                      _vm.$set(pax, "lastName", $$v);
                    },
                    expression: "pax.lastName"
                  }
                }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
              }
            }], null, true)
          })], 1), _c('b-col', {
            attrs: {
              "cols": "12",
              "md": "4"
            }
          }, [_c('validation-provider', {
            attrs: {
              "name": _vm.$t('reservation.firstName'),
              "rules": "required|compareStringChange:".concat(pax.defaultFirstName)
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(validationContext) {
                return [_c('b-form-group', {
                  staticClass: "form_label",
                  scopedSlots: _vm._u([{
                    key: "label",
                    fn: function fn() {
                      return [_c('span', [_vm._v(" " + _vm._s(_vm.$t('reservation.firstName')) + " "), _c('span', {
                        staticClass: "text-danger ml-25"
                      }, [_vm._v("(*)")])])];
                    },
                    proxy: true
                  }], null, true)
                }, [_c('b-form-input', {
                  staticClass: "font-medium-1 fw-600 text-uppercase",
                  attrs: {
                    "id": "firstName".concat(pax.paxId),
                    "lazy-formatter": "",
                    "formatter": _vm.formatterNameOnly,
                    "state": _vm.getValidationState(validationContext) === false ? false : null
                  },
                  model: {
                    value: pax.firstName,
                    callback: function callback($$v) {
                      _vm.$set(pax, "firstName", $$v);
                    },
                    expression: "pax.firstName"
                  }
                }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
              }
            }], null, true)
          })], 1), ['CHILD'].includes(pax.paxType) ? _c('b-col', {
            attrs: {
              "cols": "12",
              "md": "4"
            }
          }, [_c('validation-provider', {
            attrs: {
              "name": _vm.$t('reservation[\'DoB\']'),
              "rules": "".concat(['CHILD'].includes(pax.paxType) ? 'required|' : '', "ageGroup:OW,").concat(_vm.getBookingData.itineraries[0][0].departure.at, ",").concat(pax.paxType),
              "vid": "passenger-birthday"
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(validationContext) {
                return [_c('b-form-group', {
                  staticClass: "form_label",
                  scopedSlots: _vm._u([{
                    key: "label",
                    fn: function fn() {
                      return [_c('span', [_vm._v(" " + _vm._s(_vm.$t('reservation[\'DoB\']')) + " "), _c('span', {
                        staticClass: "text-danger ml-25"
                      }, [_vm._v("(*)")])])];
                    },
                    proxy: true
                  }], null, true)
                }, [_c('flat-pickr', {
                  staticClass: "d-none",
                  attrs: {
                    "id": "passenger-birthday".concat(pax.paxId)
                  },
                  model: {
                    value: pax.birthday,
                    callback: function callback($$v) {
                      _vm.$set(pax, "birthday", $$v);
                    },
                    expression: "pax.birthday"
                  }
                }), _c('div', {
                  staticClass: "form-control p-0",
                  class: validationContext.errors[0] ? 'is-invalid' : ''
                }, [_c('flat-pickr', {
                  staticClass: "form-control px-50 py-0",
                  attrs: {
                    "id": "passenger-birthday".concat(pax.paxId),
                    "name": _vm.$t('reservation[\'DoB\']'),
                    "config": Object.assign({}, _vm.configFlatPickr, {
                      altInputClass: 'form-control input px-50 py-0 border-transparent'
                    }),
                    "placeholder": _vm.$t('reservation[\'formatDoB\']')
                  },
                  model: {
                    value: pax.birthday,
                    callback: function callback($$v) {
                      _vm.$set(pax, "birthday", $$v);
                    },
                    expression: "pax.birthday"
                  }
                })], 1), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
              }
            }], null, true)
          })], 1) : _vm._e()] : _vm._e()], 2);
        })], 2) : _c('div', {
          staticClass: "d-flex-center"
        }, [_c('b-spinner', {
          attrs: {
            "variant": "primary"
          }
        })], 1), _c('div', {
          staticClass: "mt-1"
        }, [_c('b-form-checkbox', {
          attrs: {
            "name": "retain-checkbox"
          },
          model: {
            value: _vm.isRecalculate,
            callback: function callback($$v) {
              _vm.isRecalculate = $$v;
            },
            expression: "isRecalculate"
          }
        }, [_c('div', {
          staticClass: "text-body fw-600 font-medium-1"
        }, [_vm._v(" Tính lại giá ")])]), _vm.isRecalculate ? _c('small', {
          staticClass: "text-warning"
        }, [_vm._v(" (*) " + _vm._s(_vm.$t('reservation.recalculatePrice[\'The system will automatically recalculate the price for all passengers on the booking\']')) + " ! ")]) : _vm._e()], 1), _vm.canPriceBargainFinder && _vm.isRecalculate ? _c('b-card', {
          attrs: {
            "body-class": "py-1"
          }
        }, [_c('div', {
          staticClass: "d-flex flex-wrap gap-12px justify-content-around"
        }, [_c('b-form-checkbox', {
          staticClass: "mx-1",
          attrs: {
            "name": "retain-checkbox"
          },
          model: {
            value: _vm.isBargainFinder,
            callback: function callback($$v) {
              _vm.isBargainFinder = $$v;
            },
            expression: "isBargainFinder"
          }
        }, [_c('div', {
          staticClass: "text-body fw-600 font-medium-1"
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.bargainFinder')) + " ")])]), _c('b-form-checkbox', {
          staticClass: "mx-1",
          attrs: {
            "name": "keepGroup-checkbox"
          },
          model: {
            value: _vm.keepGroup,
            callback: function callback($$v) {
              _vm.keepGroup = $$v;
            },
            expression: "keepGroup"
          }
        }, [_c('div', {
          staticClass: "text-body fw-600 font-medium-1"
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.keepGroup')) + " ")])])], 1)]) : _vm._e()], 1)];
      }
    }])
  }), _c('BModal', {
    attrs: {
      "id": "modal-modify-pax-info-recalculate-show-price-VN1A",
      "title": _vm.$t('reservation.showCalculatePrice.title'),
      "title-class": "text-airline font-medium-4 font-weight-bolder",
      "body-class": "px-75",
      "centered": "",
      "no-close-on-backdrop": "",
      "no-close-on-esc": "",
      "size": "lg",
      "hide-header-close": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref3) {
        var close = _ref3.close;
        return [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(186, 191, 199, 0.15)',
            expression: "'rgba(186, 191, 199, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "rounded-pill px-2",
          attrs: {
            "variant": "outline-secondary"
          },
          on: {
            "click": function click($event) {
              return _vm.handleCloseModalShowPriceNew(close);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.close')) + " ")]), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "btn-gradient",
          attrs: {
            "pill": ""
          },
          on: {
            "click": _vm.handleSavePrice
          }
        }, [_c('span', {}, [_vm._v("LƯU GIÁ MỚI")])])];
      }
    }])
  }, [!_vm.isEmpty(_vm.newPriceItinerary) ? _c('div', {
    staticClass: "position-relative"
  }, [_vm.tripDetailsItinerary ? _c('div', {
    staticClass: "mb-1"
  }, [_c('div', {
    staticClass: "fw-700 font-medium-2"
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.flightInfo')) + " ")]), _c('b-form-textarea', {
    staticClass: "font-weight-bolder text-uppercase",
    staticStyle: {
      "font-size": "16px",
      "line-height": "24px",
      "background-color": "#ffffc0",
      "color": "#000",
      "font-family": "'Calibri', sans-serif",
      "overflow": "auto"
    },
    attrs: {
      "value": _vm.tripDetailsItinerary,
      "rows": "2"
    }
  })], 1) : _vm._e(), !_vm.isEmpty(_vm.newPriceItinerary) ? _c('BTableLite', {
    staticClass: "mb-1 rounded",
    attrs: {
      "bordered": "",
      "responsive": "",
      "small": "",
      "hover": "",
      "striped": "",
      "body-class": "p-0",
      "thead-class": "text-center font-weight-bolder text-nowrap",
      "tbody-class": "text-center text-nowrap",
      "fields": ['passengerType', 'net', 'tax', 'total', 'quantity', 'totalAmount'],
      "items": _vm.newPriceItinerary
    },
    scopedSlots: _vm._u([_vm._l(['passengerType', 'net', 'tax', 'total', 'quantity', 'totalAmount'], function (column, indexColumn) {
      return {
        key: "head(".concat(column, ")"),
        fn: function fn(data) {
          return [_c('div', {
            key: indexColumn
          }, [_c('span', [_vm._v(_vm._s(_vm.$t("reservation.".concat(data.column))))])])];
        }
      };
    }), {
      key: "cell(passengerType)",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(item.paxType) + " ")];
      }
    }, {
      key: "cell(net)",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_vm._v(" " + _vm._s(_vm.formatCurrency(item.netFare)) + " ")];
      }
    }, {
      key: "cell(tax)",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_vm._v(" " + _vm._s(_vm.formatCurrency(item.tax)) + " ")];
      }
    }, {
      key: "cell(total)",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('span', {
          staticClass: "fw-600"
        }, [_vm._v(_vm._s(_vm.formatCurrency(item.totalPrice)))])];
      }
    }, {
      key: "cell(quantity)",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_vm._v(" " + _vm._s(item.paxNum ? _vm.formatCurrency(item.paxNum) : 1) + " ")];
      }
    }, {
      key: "cell(totalAmount)",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [_c('span', {
          staticClass: "fw-800"
        }, [_vm._v(_vm._s(item.paxNum ? _vm.formatCurrency(item.totalPrice * item.paxNum) : _vm.formatCurrency(item.totalPrice)))])];
      }
    }], null, true)
  }) : _vm._e(), _c('p', {
    class: "text-right text-body text-nowrap mr-md-2 ".concat(_vm.isMobileView ? 'font-medium-1' : 'font-medium-2')
  }, [_vm._v(" Tổng tiền: "), _c('span', {
    class: "text-airline fw-800 ".concat(_vm.isMobileView ? 'font-medium-1' : 'font-medium-3')
  }, [_vm._v(_vm._s(_vm.formatCurrency(_vm.newPriceItinerary.reduce(function (total, item) {
    return total + (item.paxNum ? item.totalPrice * item.paxNum : item.totalPrice);
  }, 0))) + " VND")])]), _c('BAlert', {
    staticClass: "py-75 mt-1 text-center px-75",
    attrs: {
      "show": _vm.isWarningItinerary,
      "variant": "warning"
    }
  }, [_c('span', {
    class: "fw-700 ".concat(_vm.isMobileView ? 'font-medium-1' : 'font-medium-2')
  }, [_vm._v(" GIÁ VÉ CÓ SỰ THAY ĐỔI, VUI LÒNG KIỂM TRA !!! ")])])], 1) : _vm._e()])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }